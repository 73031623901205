<template>
  <login ref="login"
         :options='options'
         :news="news"
         :system="system"></login>
</template>

<script>
import Login from '@/components/Login'
import router, { resetRouter } from '@/router'

const crypto = require('crypto')

export default {
  components: {
    Login
  },
  data() {
    return {
      system: {
        name: '欢迎来到',
        title: '云博通链文物艺术品管理平台'
      },
      // 新闻
      news: [{ title: '建设规范 创新 生态的文化产业平台' }],
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          // 账号栏label名,显示在输入框上方
          // label: '用户',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          placeholder: '请输入用户名',
          clearable: true,
          maxlength: 20,
          // 校验规则
          rules: [
            { required: true, message: '请输入用户名', trigger: 'change' }
          ]
        },
        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          // label: '密码',
          icon: 'el-icon-lock',
          placeholder: '请输入密码',
          type: 'password',
          clearable: true
        },
        button: {
          label: '登录',
          clickEvent: (form) => {
            this.handleLogin(form)
          }
          // icon: 'el-icon-lock'
        }
      }
    }
  },
  methods: {
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    async handleLogin(form) {
      let params = {
        loginAccount: form.account,
        loginPassword: this.encrypt(form.password)
      }
      this.$store.dispatch('login', params)
        .then((accessRoutes) => {
          if (window.PERMISSIVE_ROUTES) {
            console.log('重复检测', router)
            // 先初始化路由，addRoutes只会加入，不会剔除重复项
            // 页面不刷新登录两个不同菜单的账号会发生菜单重叠
            resetRouter()
            router.addRoutes(accessRoutes) // 动态添加可访问路由表 // 动态路由相关
            // this.$nextTick(() => {
            //   switch (accessRoutes[0].path) {
            //     case '/record':
            //       this.$router.push({ path: '/record/list' })
            //       break
            //     case '/audit':
            //       this.$router.push({ path: '/audit/list' })
            //       break
            //     case '/identify':
            //       this.$router.push({ path: '/identify/list' })
            //       break
            //   }
            // })
          }
          this.$message.success('登录成功！')
          this.$router.push({ path: '/home' })
        })
        .catch((error) => {
          if (error.code) {
            this.$message.error(error)
          }
          this.$refs.login.resetButton() // 恢复按钮
        })
    }
  }
}
</script>
